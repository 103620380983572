/* eslint no-invalid-this: off, max-len: off */
$(document).on('click', '.js-edit-button', function() {
    let editPath = $(this).data('edit-path');
    let $modal = $('#'+$(this).data('edit-modal'));
    $.ajax({
        url: editPath,
        type: 'get',
        success: function(response) {
            $modal.find('.modal-body').html(response);
            $modal.modal('show');
            $modal.find('form').trigger('ajax_form.loaded');
        }
    });
});

$(document).on('click', '.js-form-save', function(event) {
    let $modal = $(this).closest('.modal');
    let $form = $modal.find('form');
    let formData = new FormData($form.get(0));

    $.ajax({
        type: 'post',
        url: $form.attr('action'),
        data: formData,
        processData: false,
        contentType: false,
        success: function(response) {
            if (response.hasOwnProperty('success')) {
                if($modal.data('editReload') !== undefined && !$modal.data('editReload')) {
                    $('.m_datatable').mDatatable().reload();
                    $modal.modal('hide');
                    toastr.success(Translator.trans('common.changes_saved', [],'company'));
                } else {
                    location.reload();
                }
            } else {
                $modal.find('.modal-body').html(response);
                $modal.find('form').trigger('ajax_form.loaded');
            }
        }
    });
});

$('body').on('ajax_form.loaded', function(event) {
    let $modal = $(event.target).closest('.modal');

    $('.m-select2').select2({
        width: '100%'
    });

    $modal.find('form').on('keyup keypress', function(e) {
        let keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
            e.preventDefault();
            return false;
        }
    });
});
