/* eslint-disable no-invalid-this */
let slider = $('.slider');
$(document).ready(function() {
    $('.form-check').addClass('checkbox');
    $(document).on('click', 'input[type=\'text\'], input[type=\'email\'], input[type=\'password\'], ' +
        '.slider, .selectpicker', function() {
        $('input[type=\'text\'], input[type=\'email\'], input[type=\'password\']').on('focus', function() {
            let bg = $(this).parent();
            bg.addClass('show');
        });
        $('input[type=\'text\'], input[type=\'email\'], input[type=\'password\']').on('focusout', function() {
            let bg = $(this).parent();
            bg.removeClass('show');
        });
        $('.bs-searchbox').find('input').attr('placeholder', 'Rechercher');
        slider.slider();
    });
    /* Narrow header when scrolling */
    $(window).scroll(function() {
        if ($(this).scrollTop() > 0 && $(this).width() > 768) {
            $('.main-header').addClass('scroll');
            $('.profile-menu').addClass('scroll');
            $('#logo').attr('src', '/images/logo-no-baseline-horizontal.png');
        } else {
            $('.main-header').removeClass('scroll');
            $('.profile-menu').removeClass('scroll');
            $('#logo').attr('src', '/images/logo-baseline-horizontal-hd.png');
        }
    });

    /* Burger menu handler */
    let sidebar = $('.sidebar');
    let burgerButton = $('.burger');
    burgerButton.change(
        function() {
            if ($(this).is(':checked')) {
                sidebar.addClass('activated-menu');
            } else {
                sidebar.removeClass('activated-menu');
            }
        });
    burgerButton.on('click',
        function() {
            if (!$(this).hasClass('opened')) {
                sidebar.addClass('activated-menu');
                $(this).addClass('opened');
            } else {
                sidebar.removeClass('activated-menu');
                $(this).removeClass('opened');
            }
        });

    /* Profile menu */
    $('#profile').on('click', function() {
        let isOpened = $(this).hasClass('opened');
        let profileMenu = $('.profile-menu');
        let dropdownIcon = $(this).find('.js-dropdown-icon');

        if (isOpened) {
            $(this).removeClass('opened');
            dropdownIcon.html('keyboard_arrow_down');
            profileMenu
                .animate({
                    opacity: 0
                }, 100, function() {
                    profileMenu.css('display', 'none');
                });

            return;
        }

        $(this).addClass('opened');
        dropdownIcon.html('keyboard_arrow_up');
        profileMenu
            .css('display', 'block')
            .animate({
                opacity: 1
            }, 100);
    });

    $(document).on('click', '[data-href]', function() {
        // disable navigation if it's part of slider and it's not current
        let $isMapSlider = $(this).closest('.js-block-activity-slider').hasClass('js-map-slider');
        let $isCurrentSlide = $(this).closest('.slick-slide').hasClass('slick-current');
        if ($isMapSlider && !$isCurrentSlide) {
            return;
        }

        location.href = $(this).data('href');
    });
});
